/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria'), url('Geometria.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-Italic'), url('Geometria-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-Thin'), url('Geometria-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-ThinItalic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-ThinItalic'), url('Geometria-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-ExtraLight'), url('Geometria-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-ExtraLightItalic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-ExtraLightItalic'), url('Geometria-ExtraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-Light'), url('Geometria-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-LightItalic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-LightItalic'), url('Geometria-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-Medium'), url('Geometria-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-MediumItalic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-MediumItalic'), url('Geometria-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-Bold'), url('Geometria-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-BoldItalic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-BoldItalic'), url('Geometria-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-ExtraBold'), url('Geometria-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-ExtraBoldItalic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-ExtraBoldItalic'), url('Geometria-ExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-Heavy'), url('Geometria-Heavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Geometria-HeavyItalic';
    font-style: normal;
    font-weight: normal;
    src: local('Geometria-HeavyItalic'), url('Geometria-HeavyItalic.woff') format('woff');
    }